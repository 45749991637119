import React from 'react';
import Button from '../../button';

class ListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props
    const item = props.item
    const attributes = item.attributes_hash || {}
    const selected = props.current ? 'selected' : ''
    const className = 'list-item library show ' + selected

    return (
      <li className={className}>
        <div className='image-wrapper'>
          {attributes.link ?
            <a href={attributes.link}><img src={this.props.imageUrl} /></a> :
            <img src={this.props.imageUrl} />
          }
        </div>
        {(attributes.name || attributes.description) &&
          <div className='item-info'>
            <div className='name'>
              {attributes.name}
            </div>
            <div className='description'>
              {attributes.description}
            </div>
          </div>
        }
      </li>
    )
  }
}

export default ListItem;
