import React from 'react';
import Button from '../button';
import Fetch from './list/fetch'
import ListItem from './image_album/list_item'
import {mediaUrl} from '../base/functions';

const AjaxAPI = window.AjaxAPI;

class SettingsFormProps extends React.Component {
  constructor(props) {
    super(props);

    this.updateSettingsFormProps()
  }

  componentDidUpdate(prevProps) {
    this.updateSettingsFormProps()
  }

  updateSettingsFormProps () {
    const signals = this.props.namedSignals[this.props.albumListKey]
    const albums = (signals && signals.list) || []
    const formProps = this.props.namedSignals.settingsFormProps || {}
    const fields = {
      album_id: {
        options: [{
          label: 'None selected',
          value: null
        }]
      },
      layout: {
        options: [
          { label: 'Horizontal Scroll', value: 'horizontal-scroll' },
          { label: 'One Column', value: 'columns one-column' },
          { label: 'Two Column', value: 'columns two-column' },
          { label: 'Three Column', value: 'columns three-column' }
        ]
      }
    }

    albums.forEach(function(album, i) {
      fields.album_id.options.push({
        label: album.attributes_hash.name,
        value: album.id
      })
    })

    if (JSON.stringify(formProps.image_album) === JSON.stringify(fields)) {
      return
    }

    this.props.setNamedSignals([
      { key: 'settingsFormProps', signal: 'image_album', data: fields }
    ])
  }

  render() { return(null) }
}

class ImageAlbumBlock extends React.Component {
  constructor(props) {
    super(props);

    this.albumListKey = '/block_data/album?data_type=image'
    this.settingsKey = '/block_data/album?data_type=image'
  }

  componentDidUpdate(prevProps) {
    this.updateCurrentAlbum()
  }

  updateCurrentAlbum () {
    const _this = this
    const albumId = this.props.block.instance.attributes_hash.album_id
    const signals = this.props.namedSignals['currentAlbum']
    const album = (signals && signals.image) || {}

    if (albumId && (albumId != album.id)) {
      (new AjaxAPI).get('/block_data/album/'+albumId, function(data){
        const album = data.data
        const collection = album.collection || []
        _this.setState({playlist: collection})
        _this.props.setNamedSignals([
          { key: 'currentAlbum', signal: 'image', data: album }
        ])
      })
    }
  }

  imageUrl(item) {
    const attributes = item.attributes_hash || {}

    if (attributes.file) {
      return mediaUrl(
        [this.props.user.cdn, '/files/', item.data_type, '/', attributes.file]
      )
    }

    return null
  }

  coverUrl(item) {
    const attributes = item.attributes_hash || {}

    if (attributes.cover) {
      return mediaUrl(
        [this.props.user.cdn, '/files/image/', attributes.cover]
      )
    }
    return null
  }

  render() {
    const props = this.props
    const block_attributes = props.block.instance.attributes_hash
    const signals = this.props.namedSignals['currentAlbum']
    const album = (signals && signals.image) || {}
    const album_attributes = album.attributes_hash || {}
    const coverUrl = this.coverUrl(album)
    const collection = album.collection || []
    const className = 'image-album ' + block_attributes.layout

    return (
      <div className={className}>
        <div className='album-info'>
          <div className='title'>
            {album_attributes.name}
          </div>
        </div>
        <ul className='list'>
          {collection.map((o) =>
            <ListItem
              key={o.id}
              item={o}
              imageUrl={this.imageUrl(o)}
            />
          )}
        </ul>
        <Fetch {...props}
          signalsKey={this.albumListKey}
        />
        {this.props.edit &&
          <SettingsFormProps {...props}
            albumListKey={this.albumListKey}
            settingsKey={this.settingsKey}
          />
        }
      </div>
    )
  }
}

export default ImageAlbumBlock;
